import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const Card = ({ title, rating }) => {
  const getNumericRating = (rating) => {
    switch (rating) {
      case "OneStar":
        return 1;
      case "OneHalfStar":
        return 1.5;
      case "TwoStar":
        return 2;
      case "TwoHalfStar":
        return 2.5;
      case "ThreeStar":
        return 3;
      case "ThreeHalfStar":
        return 3.5;
      case "FourStar":
        return 4;
      case "FourHalfStar":
        return 4.5;
      case "FiveStar":
        return 5;
      default:
        return 0;
    }
  };

  const renderStars = (numericRating) => {
    const stars = [];
    for (let i = 0; i < Math.floor(numericRating); i++) {
      stars.push(<FaStar key={i} />);
    }
    if (numericRating % 1 !== 0) {
      stars.push(<FaStarHalfAlt key="half" />);
    }
    return stars;
  };

  const numericRating = getNumericRating(rating);

  return (
    <div className="w-full px-12 py-5 rounded-lg shadow-shadowOne flex items-center bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-black hover:to-[#1e2024] transition-colors duration-100 group">
      <div className="overflow-y-hidden">
        <div className="flex h-full flex-col gap-4 translate-y-2 group-hover:translate-y-0 transition-transform duration-500">
          <div className="flex py-4 flex-col gap-4">
            <h2 className="text-xl md:text-2xl font-titleFont font-bold text-gray-300">
              {title}
            </h2>
            <div className="flex text-2xl text-designColor">
              {renderStars(numericRating)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
