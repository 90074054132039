import React from "react";

const LeftBanner = () => {
  return (
    <div className="w-full lgl:w-1/2 flex flex-col sm:gap-20 gap-10">
      <div className="flex flex-col sm:gap-5 gap-2">
        <h4 className=" text-md font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-3xl font-bold text-white">
          Hi, I'm{" "}
          <span className="text-designColor capitalize">Abasifreke James,</span>
        </h1>
        <h2 className="text-3xl font-bold text-white -mt-4">
          a <span>professional Software Developer</span>
        </h2>
      </div>
    </div>
  );
};

export default LeftBanner;
