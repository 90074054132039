import React from "react";

import Title from "../layouts/Title";
import Card from "./Card";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const Skills = () => {
  const { skills, isLoading } = useSelector((state) => state.projects);

  return (
    <section
      id="skills"
      className="w-full py-20 text-center border-b-[1px] border-b-black">
      <Title des="Skills" />
      {isLoading ? (
        <div className="p-5 flex items-center justify-center flex-col">
          <ClipLoader color="#ff014f" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 xl:gap-4">
          {skills?.map((item, index) => {
            return <Card title={item?.name} rating={item?.level} key={index} />;
          })}
        </div>
      )}
    </section>
  );
};

export default Skills;
