import React from "react";
import Title from "../layouts/Title";
import ProjectsCard from "./ProjectsCard";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Grid } from "@mui/material";

const Projects = () => {
  const { projects, isLoading } = useSelector((state) => state.projects);
  return (
    <>
      {isLoading ? (
        <div className="p-5 flex items-center justify-center flex-col">
          <ClipLoader color="#ff014f" />
        </div>
      ) : (
        <section
          id="projects"
          className="w-full  py-20 border-b-[1px] border-b-black">
          <div className="flex items-center justify-center text-center">
            <Title title="" des="Recent Works" />
          </div>
          <div className="  ">
            <Grid container>
              {projects?.map((item, index) => {
                return (
                  <Grid xs={12} sm={12} md={4} className="p-1">
                    <ProjectsCard
                      key={index}
                      title={item?.title}
                      des={item?.description}
                      src={item?.gallery?.[0]?.photo}
                      item={item}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </section>
      )}
    </>
  );
};

export default Projects;
