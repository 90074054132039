import React from "react";
import { useSelector } from "react-redux";
import { API_URL } from "../../features/api";

const Footer = () => {
  const { contacts } = useSelector((state) => state.projects);

  return (
    <div className="w-full py-20 h-auto border-b-[1px] border-b-black flex items-center justify-center">
      <div className="w-full h-full text-center flex-col gap-8 flex items-center justify-center">
        <h1 className="text-white font-black sm:text-[30px] text-[20px]">
          Other ways to contact me
        </h1>
        <div className="flex gap-4">
          {contacts?.map((item, index) => (
            <a href={item?.link} className="bannerIcon p-3" key={index}>
              <img src={`${API_URL}/${item?.logo}`} alt="" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
