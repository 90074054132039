import React from "react";
import Title from "../layouts/Title";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full text-center py-20 border-b-[1px] border-b-black">
      <Title title="" des="About Me" />
      <div>
        <p>
          I'm Abasifreke James, a Software Developer with over 7 years of
          working experience. With a strong foundation in Computer Science and
          knowledge of modern programming languages and technologies, I find
          myself fit and adaptable to many development environments.
        </p>
        <br />
        <br />

        <p>
          When I'm not coding, I enjoy exploring new destinations and immersing
          myself in different cultures through travel. As an avid gamer, I find
          excitement and inspiration in playing EA Sports, God of War, and Grand
          Theft Auto. These hobbies not only offer a perfect balance to my
          professional life but also fuel my creativity and problem-solving
          skills.
        </p>
        <br />
        <p>So much about me already. Let me hear from you!</p>
      </div>
    </section>
  );
};

export default Features;
