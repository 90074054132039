import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { API_URL } from "../features/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "#212428",

  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, handleClose, item }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "#212428",

            boxShadow: 24,
            p: 4,
            height: { xs: "60vh", sm: "90vh" },
            width: { xs: "400px", sm: "600px" },
            overflowY: "scroll",
            bgcolor: "#212428",
          }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", color: "#c4cfde" }}>
            {item?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ color: "#c4cfde" }}>
            <p
              className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300"
              dangerouslySetInnerHTML={{
                __html: item?.description,
              }}
            />
          </Typography>

          <Box className="  grid items-start grid-cols-1  gap-2 xl:gap-4 mt-5  ">
            {item?.gallery?.map((ga, index) => {
              return (
                <Box
                  key={index}
                  className="  p-5 rounded-lg shadow-shadowOne   bg-gradient-to-r
                from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000">
                  <img
                    src={`${API_URL}/${ga?.photo}`}
                    alt=""
                    className="rounded-md"
                  />
                  <div>
                    <h4
                      className="text-[12px] font-bold opacity-80 mt-2"
                      style={{ color: "#c4cfde " }}>
                      Description
                    </h4>
                    <p
                      className="text-[11px]"
                      style={{ color: "#c4cfde " }}
                      dangerouslySetInnerHTML={{ __html: ga?.description }}
                    />
                  </div>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
