import { createSlice } from "@reduxjs/toolkit";
import projectService from "./projectService";
import { createAsyncThunkWithHandler } from "../api";

const projects = JSON.parse(localStorage.getItem("axxios_projects"));
const skills = JSON.parse(localStorage.getItem("axxios_skills"));
const profile = JSON.parse(localStorage.getItem("axxios_profilePic"));
const contacts = JSON.parse(localStorage.getItem("axxios_contacts"));

const initialState = {
  projects: projects ? projects : null,
  skills: skills ? skills : null,
  profile: profile ? profile : null,
  contacts: contacts ? contacts : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSkills = createAsyncThunkWithHandler(
  "projects/getSkills",
  async (_, thunkAPI) => {
    return await projectService.getSkills();
  }
);

export const getContacts = createAsyncThunkWithHandler(
  "projects/getContacts",
  async (_, thunkAPI) => {
    return await projectService.getContacts();
  }
);

export const sendMessage = createAsyncThunkWithHandler(
  "projects/sendMessage",
  async (data, thunkAPI) => {
    return await projectService.sendMessage(data);
  }
);

export const getProfilePic = createAsyncThunkWithHandler(
  "projects/getProfilePic",
  async (_, thunkAPI) => {
    return await projectService.getProfilePic();
  }
);

export const getProjects = createAsyncThunkWithHandler(
  "projects/getProjects",
  async (_, thunkAPI) => {
    return await projectService.getProjects();
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkills.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSkills.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.skills = action.payload;
        state.isSuccess = true;
      })
      .addCase(getSkills.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.contacts = action.payload;
        state.isSuccess = true;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(getProfilePic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfilePic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.profile = action.payload;
        state.isSuccess = true;
      })
      .addCase(getProfilePic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.projects = action.payload;
        state.isSuccess = true;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "message sent";
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "Something went wrong message not sent";
        state.isSuccess = false;
      });
  },
});

export const { reset } = projectsSlice.actions;
export default projectsSlice.reducer;
