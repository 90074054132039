import axios from "axios";
import { API_URL } from "../api";

const getSkills = async () => {
  const response = await axios.get(`${API_URL}/skills`);

  if (response.data) {
    localStorage.setItem("axxios_skills", JSON.stringify(response.data));
  }
  return response.data;
};

const getProjects = async () => {
  const response = await axios.get(`${API_URL}/projects`);

  if (response.data) {
    localStorage.setItem("axxios_projects", JSON.stringify(response.data));
  }
  return response.data;
};

const getContacts = async () => {
  const response = await axios.get(`${API_URL}/contact`);

  if (response.data) {
    localStorage.setItem("axxios_contacts", JSON.stringify(response.data));
  }
  return response.data;
};

const getProfilePic = async () => {
  const response = await axios.get(`${API_URL}/profilepicture`);
  if (response.data) {
    localStorage.setItem("axxios_profilePic", JSON.stringify(response.data));
  }
  return response.data;
};

const sendMessage = async (data) => {
  const response = await axios.post(`${API_URL}/message`, data);

  return response.data;
};

const authService = {
  getSkills,
  getProjects,
  getProfilePic,
  getContacts,
  sendMessage,
};

export default authService;
