import React from "react";
import { useSelector } from "react-redux";
import { API_URL } from "../../features/api";

const RightBanner = () => {
  const { profile } = useSelector((state) => state.projects);

  const image = `${API_URL}/${profile?.url}`;
  return (
    <div className="w-full mt-5 lgl:w-1/2 flex justify-center items-center relative">
      <img
        className="w-[350px] h-[450px] rounded-md lgl:w-[350px] lgl:h-[450px] z-10"
        src={image}
        alt="bannerImg"
      />
      <div className="absolute bottom-0 w-[350px] h-[300px] lgl:w-[400px] lgl:h-[500px] bg-gradient-to-r from-[#1e2024] to-[#202327] shadow-shadowOne flex justify-center items-center"></div>
    </div>
  );
};

export default RightBanner;
