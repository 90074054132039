import React, { useState } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";

import { navLinksdata } from "../../constants";
import { useSelector } from "react-redux";
import { API_URL } from "../../features/api";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { contacts } = useSelector((state) => state.projects);

  return (
    <div className="w-full sticky top-0 z-50 bg-bodyColor mx-auto  font-titleFont border-b-[1px] border-b-gray-600">
      <div className="w-[90%]  h-24 m-auto flex justify-between items-center">
        <div>
          <h1 className="text-white font-black sm:text-[30px] text-[25px]">
            Abasifreke James.
          </h1>
        </div>
        <div>
          <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
            {navLinksdata.map(({ _id, title, link }) => (
              <li
                className="text-[11px] font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                key={_id}>
                <Link
                  activeClass="active"
                  to={link}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}>
                  {title}
                </Link>
              </li>
            ))}
          </ul>
          <span
            onClick={() => setShowMenu(!showMenu)}
            className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer">
            <FiMenu />
          </span>
          {showMenu && (
            <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
              <div className="flex flex-col gap-8 py-2 relative">
                <div>
                  <h1 className="text-white font-black text-[25px]">
                    Abasifreke James.
                  </h1>
                </div>
                <ul className="flex flex-col gap-4">
                  {navLinksdata.map((item) => (
                    <li
                      key={item._id}
                      className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300">
                      <Link
                        onClick={() => setShowMenu(false)}
                        activeClass="active"
                        to={item.link}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}>
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="flex flex-col gap-4">
                  <h2 className="text-base uppercase font-titleFont mb-4">
                    Find me in
                  </h2>
                  <div className="flex flex-wrap gap-2">
                    {contacts?.map((item, index) => (
                      <a
                        href={item?.link}
                        className="bannerIcon p-3"
                        key={index}>
                        <img src={`${API_URL}/${item?.logo}`} alt="" />
                      </a>
                    ))}
                  </div>
                </div>
                <span
                  onClick={() => setShowMenu(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer">
                  <MdClose />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
