import React from "react";

import { API_URL } from "../../features/api";
import BasicModal from "../Modal";

const ProjectsCard = ({ title, des, src, item }) => {
  const image = `${API_URL}/${src}`;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <BasicModal open={open} handleClose={handleClose} item={item} />
      <div
        className="w-full p-3 my-5 xl:px-6 h-auto xl:py-5 
    rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r
     from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000">
        <div className="w-full h-[80%] overflow-hidden rounded-lg">
          <img
            className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
            src={image}
            alt="src"
          />
        </div>
        <div className="w-full mt-5 flex flex-col  gap-6">
          <div>
            <div className="flex items-center gap-1 justify-between">
              <h3 className="text-base word-wrap break-word uppercase text-designColor font-normal">
                {title}
              </h3>
            </div>
            <p
              className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300"
              dangerouslySetInnerHTML={{
                __html:
                  des?.length > 100 ? `${des.substring(0, 100)} ....` : des,
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <button
            onClick={handleOpen}
            className="border bg-neutral-900 px-2 text-[12px] font-bold py-2 rounded-md">
            View Project
          </button>
        </div>
      </div>
    </>
  );
};

export default ProjectsCard;
